/**
 * Third-party libraries
 */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { motion } from "framer-motion"
import styled from "styled-components"

import SEO from "../components/SEO"

/**
 * First-party files
 */
// components
import CardButton from "components/Card/CardButton"
import Footer from "components/Footer"
import Layout from "components/Layout"
import { Card, StyledLogo } from "components/secondpage.style"
import SlideHeader from "components/SlideHeader"
import TabletHeader from "components/TabletHeader"
// styles
import theme from "styles/theme"

const StyledImage = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
  .one {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    img {
      height: 200px;
      width: auto;
    }
  }
  .t1 {
    position: absolute;
    width: 100px;
    top: 40px;
    left: 170px;
  }
  .f1 {
    position: absolute;
    width: 100px;
    bottom: 50px;
    left: 10px;
  }
  .two {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
      height: auto;
    }
  }
  .t2 {
    position: absolute;
    width: 100px;
    bottom: 40px;
    left: 190px;
  }
  .f2 {
    position: absolute;
    width: 100px;
    top: 40px;
    left: 10px;
  }
  .three {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
      height: auto;
    }
  }
  .t3 {
    position: absolute;
    width: 100px;
    bottom: 30px;
    left: 100px;
  }
  .f3 {
    position: absolute;
    width: 100px;
    top: 40px;
    left: 10px;
  }
  .four {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
  }
  .t4 {
    position: absolute;
    width: 100px;
    bottom: 48px;
    right: 60px;
  }
  .f4 {
    position: absolute;
    width: 100px;
    top: 40px;
    left: 10px;
  }
`

const StyledCards = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`

const setInitial = entry => {
  if (entry.state.prevPage) {
    return { x: entry.state.x }
  }

  return { x: 0, opacity: 0 }
}

const setAnimation = (transitionStatus, exit) => {
  if (transitionStatus === "exiting") {
    if (exit.state.nextPage === "thirtyYears") {
      return { x: -exit.state.x }
    }
    if (exit.state.nextPage === "bitsOfKnowledge") {
      return { x: -exit.state.x }
    }
  }
  return { x: 0, opacity: 1 }
}

const setTransition = entry => {
  // Used for swipe animation which is applied when page is requested
  // by clicking header
  if (Object.keys(entry.state).length > 0) {
    return { ease: "easeOut", duration: entry.length }
  }

  // Used for fade animation which is applied when page is NOT requested
  // by clicking header
  if (Object.keys(entry.state).length === 0) {
    return { ease: "easeOut", duration: 0.6 }
  }
}

const LaboPage = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": `${data.seo.top.pass}`,
            name: `${data.seo.top.title}`,
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": `${data.seo.page.labo.pass}`,
            name: `${data.seo.page.labo.title}`,
          },
        },
      ],
    },
  ]

  return (
    <Layout>
      <SEO
        title={data.seo.page.labo.title}
        pageUrl={data.seo.page.labo.pass}
        description={data.seo.page.labo.description}
        imgUrl="/mainVisual.jpg"
        type="article"
        jsonld={jsonld}
      />
      <SlideHeader location={location} />
      <TabletHeader location={location} />

      <motion.div
        initial={setInitial(entry)}
        animate={setAnimation(transitionStatus, exit)}
        transition={setTransition(entry)}
      >
        <StyledLogo>
          <StaticImage
            src="../images/doll3.svg"
            alt="フランソア・帝人ラボ"
            placeholder="none"
            className="leftImg"
          />
          <StaticImage
            src="../images/logoLabo.svg"
            alt="食物繊維ラボ"
            placeholder="none"
          />
          <p>{data.lead.laboCard.leadText}</p>
          <StaticImage
            src="../images/doll1.svg"
            alt="フランソア・帝人ラボ"
            placeholder="none"
            className="rightImg"
          />
        </StyledLogo>
        <StyledCards>
          <Card bgColor={theme.colors.primary.pink}>
            <StyledImage>
              <StaticImage
                src="../images/francoisLogo.png"
                alt="フランソア"
                className="f1"
                placeholder="none"
              />
              <StaticImage
                src="../images/logo-teijin.jpg"
                alt="だけじゃない帝人"
                className="t1"
                placeholder="none"
              />
              <StaticImage
                className="one"
                src="../images/doll1.svg"
                alt="フランソア・帝人ラボ"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.withTeijin.leadText}</p>
            <CardButton
              link="/labo/with-teijin/"
              sdColor={theme.colors.shadow.pink}
            />
          </Card>
          <Card bgColor={theme.colors.primary.pink}>
            <StyledImage>
              <StaticImage
                src="../images/francoisLogo.png"
                alt="フランソア"
                className="f2"
                placeholder="none"
              />
              <StaticImage
                src="../images/logo-teijin.jpg"
                alt="だけじゃない帝人"
                className="t2"
                placeholder="none"
              />
              <StaticImage
                className="two"
                src="../images/doll2.svg"
                alt="フランソア・帝人辞書"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.bacterialFlora.leadText}</p>
            <CardButton
              link="/labo/bacterial-flora/"
              sdColor={theme.colors.shadow.pink}
            />
          </Card>
          <Card bgColor={theme.colors.primary.pink}>
            <StyledImage>
              <StaticImage
                className="three"
                src="../images/doll3.svg"
                alt="フランソア・帝人ラボ"
                placeholder="none"
              />
              <StaticImage
                src="../images/francoisLogo.png"
                alt="フランソア"
                className="f3"
                placeholder="none"
              />
              <StaticImage
                src="../images/logo-teijin.jpg"
                alt="だけじゃない帝人"
                className="t3"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.varietyOfFiber.leadText}</p>
            <CardButton
              link="/labo/variety-of-fiber"
              sdColor={theme.colors.shadow.pink}
            />
          </Card>
          <Card bgColor={theme.colors.primary.pink}>
            <StyledImage>
              <StaticImage
                className="four"
                src="../images/presentation.png"
                alt="フランソア・帝人ラボ"
                placeholder="none"
                imgStyle={{
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
              <StaticImage
                src="../images/francoisLogo.png"
                alt="フランソア"
                className="f4"
                placeholder="none"
              />
              <StaticImage
                src="../images/logo-teijin.jpg"
                alt="だけじゃない帝人"
                className="t4"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.report202308.leadText}</p>
            <CardButton
              link="/labo/report-2023-08"
              sdColor={theme.colors.shadow.pink}
            />
          </Card>
        </StyledCards>
        <Footer />
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "laboPage" }) {
      withTeijin {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
      bacterialFlora {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
      varietyOfFiber {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
      report202308 {
        leadText
      }
    }
    lead: componentsYaml {
      laboCard {
        leadText
      }
      bitsOfKnowledgeCard {
        leadText
      }
      thirtyYearsCard {
        leadText
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        labo {
          description
          pass
          title
          bacterialFlora {
            description
            pass
            title
          }
          varietyFiber {
            description
            pass
            title
          }
          withTeijin {
            description
            pass
            title
          }
        }
      }
    }
  }
`

export default LaboPage
